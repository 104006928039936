<template>
  <div class="markdown-list">
    <div class="markdown-form">
      <div>
        <mu-text-field
            v-model="postForm.title"
            placeholder="关键词"
            @keydown.enter='getMarkdownList'
            style="padding:0;height: 32px;margin-bottom: 0">
        </mu-text-field>
      </div>
      <div style="display: flex;align-items: center;color:rgba(0, 0, 0, 0.87);font-size: 16px">
        <span style="font-weight: bold">排序：</span>
        <i class="fa-solid fa-arrow-down-short-wide"
        style='color:var(--at-color-meta)'
        v-if="postForm.sort=='desc'"
         @click='toggleSort'></i>
         <i class="fa-solid fa-arrow-up-short-wide handle"
         style='color:var(--at-color-meta)'
         v-else
         @click='toggleSort'></i>
            
        
      </div>
    </div>
    <div class='markdown-content'>
      <div v-for='item in markdownList' class="markdown-item">
        <h1 class='title' @click='toDetail(item.id)'>{{ item.title }}</h1>
        <div class="tags" >
          标签：
          <span class='tag-item' v-if= '!item.descriptionId'>暂无</span>
          <span class='tag-item' v-for = "key in tranMarkdownType(item.descriptionId)">{{key}}</span>
        </div>
        <div class="create-info">
          <span class="create-name">作者:{{ item.createName }}</span>
          <span class="create-time">时间:{{ item.createTime }}</span>
        </div>
      </div>
    </div>
    <mu-flex justify-content="center">
      <mu-pagination
          :current.sync="postForm.page"
          :page-size="postForm.pageSize"
          :total="total"
          circle
          raised
          @change='pageNoChange'>
      </mu-pagination>
    </mu-flex>
  </div>
</template>
<script>
import {
  getMarkdownList,
  getMarkdownType
} from '@/api/common/userinfo.js'

export default {
  data: () => {
    return {
      html: '',
      markdownList: [],
      markdownTypeList:[],
      total: 0,
      postForm: {
        pageSize: 10,
        page: 1,
        sort: 'desc',
        title: '',
        status: 1
      }
    }
  },
  async mounted() {
    let res = await getMarkdownType()
    this.markdownTypeList = res.data.list
    this.getMarkdownList()
  },
  methods: {
    tranMarkdownType(id){
        let arr = id.split(',')
        let arrText = []
        this.markdownTypeList.forEach(item => {
          if(arr.includes(item.code+'')){
            arrText.push(item.name)
          }
        })
        return arrText
    },
    getMarkdownTypeList(){
      getMarkdownType().then(res => {
        this.markdownTypeList = res.data.list
      })
    },
    getMarkdownList() {
      getMarkdownList(this.postForm).then(res => {
        this.markdownList = res.data.list;
        this.total = res.data.total
        
      })
    },
    toDetail(id) {
      this.$router.push({
        path: "/detail/" + id
      })
    },
    pageNoChange(pageNo) {
      document.querySelector('html').scrollTop=0
      this.postForm.page = pageNo
      this.getMarkdownList()
    },
    toggleSort() {
      if (this.postForm.sort == 'desc') {
        this.postForm.sort = 'asc'
      } else {
        this.postForm.sort = 'desc'
      }
      this.postForm.page = 1;
      this.getMarkdownList()
    },
  }
}
</script>
<style lang="less" scoped>
.markdown-list {
  width: 100%;
  .markdown-form {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }
  
  .markdown-content {
    min-height: calc(100vh - 260px);
    
    .markdown-item {
      padding: 5px 15px;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: 10px;
      
      .title {
        margin: 5px 0;
        
        &:hover {
          text-decoration-line: underline;
        }
      }
      
      .tags {
        margin-bottom: 10px;
        
        .tag-item {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          border: 1px solid #cccccc26;
          border-radius: 15px;
          line-height: 24px;
          margin-right: 5px;
          background-color: #cccccc26;
        }
      }
      
      .create-info {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  
}
</style>